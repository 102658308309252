import axios from "axios";

export const getOprByOprId = (fields) => (dispatch) => {
    //loading
    dispatch({ type: "REQUEST_GET_OPR_BY_OPR_ID" });
    return axios({
        method: "GET",
        url: `https://api-pga-cust-develop.panggil-aku.com/api/v1/operator/${fields.opr_id}`,
        headers : {Authorization : fields.token}
    })
    .then(function (response) {
      // console.log('RESPONSE', response)
      dispatch({ type: "GET_OPR_BY_OPR_ID", payload: response.data.data[0] });
    })
    .catch(function (error) {
      dispatch({ type: "ERROR_GET_OPR_BY_OPR_ID", payload: error.response });
    });
};
